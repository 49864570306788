import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
const Contact = () => {
    return (
      <Layout>
        <Head
          title="Glossary"
          descriptionContent="All the terms you will ever come across in the world of precious metals."
        />
        <h2>Glossary</h2>
        <p>
          Below you will a list of common terms and phrases associated with
          precious metals. Many forums and sites pertaining to precious metals
          and coin collecting reference these terms. Here is the definitive
          list.
        </p>

        <ul>
        <li>
            <b>brilliant uncirculated (BU):</b> Are coins that have not been in circulation and would be graded between 60-70 on the Sheldon Coin Grading Scale. Most collectors' coins you purchase that come encapsulated are referred to as being in BU condition.
        </li>
          <li>
            <b>bullion:</b> precious metals that are not given a face value denomination. A Royal Canadian Mint Silver Maple Leaf has a $5.00 face value, making it a coin. The mint also produces a 10-ounce bar which has no face value, making it bullion. 
          </li>
          <li>
              <b>cast silver/gold: </b>When silver or gold is melted and poured into a casting mold. These molds usually have no open face and do not show pour lines.
          </li>
          <li>
            <b>coin:</b> Legal tender that is authorized by the government and given a face value. For example, the Silver Maple Leaf is a coin with a $5 face value.
          </li>
          <li>
            <b>constitutional silver / junk silver:</b> Most nations historically had coins that had silver content in the past. United States constitutional silver ended in the year of 1964, when the government stopped using silver in coinage. Constitutional silver does not have any numismatic value. To calculate silver try <a href="http://coinapps.com/silver/coin/canadian/calculator/">coinapps.com</a> to calculate the weight of silver and value in your junk silver.
          </li>
          <li>
            <b>culled: </b> A coin or round clearly shows signs of circulation or scratched. American Silver Eagles that are culled might have toning, scratches or even dents. However, from a precious metal standpoint they still contain the value of the weight specified. Culled coins come at a significant discount compared to uncirculated coins.
          </li>
          <li>
            <b>mintage:</b> The total amount of a particular coin produced. Coins with a mintage under 50,000 thousand come with a higher premium. American Silver Eagles  have a very high mintage; in the 10's of millions.
          </li>
          <li>
            <b>numismatic value:</b> A coin that has an external value above the coins face value. Rare, old, out of circulation coins in good condition have a high numismatic value. The coins in current circulation that are mass produced and easily available have no numismatic value.
          </li>
          <li>
              <b>poured silver / gold: </b> Precious metals that are poured into an open mold. Poured gold and silver often develop beautiful pour lines and are more expensive to purchase because they require the skill of a human and cannot be automated. They are often highly collectible. Some poured silver is connected to popular precious metal enthusiasts like <a href="https://backyardbullion.com/hand-poured-silver-made-to-order/"> backyard bullion</a>
          </li>
          <li>
            <b>premium:</b> The percentage a buyer pays for a precious metal
            above the current spot price. Low mintage, well designed coins, from
            established mints come with a high premium. Regular bars come with a
            low premium.
          </li>
          <li>
            <b>round:</b> Privately minted non legal tender precious metal. A round will not have a face value but will have the same purity and weight as a coin, but with less premium. Several online precious metal suppliers produce their own rounds, like Apmex for example.
          </li>
          <li>
            <b>spot:</b> The current market price for a raw commodity. In our
            case precious metals. The spot price is the metal value found in the
            bar or coin.
          </li>
          <li>
              <b>stacker:</b> Someone who is only concerned with purchasing silver and gold to as close to spot as possible. They usually focus on rounds, and bars of a higher weight to avoid paying a premium. This is the easiest form of investing in gold and silver.
          </li>
          <li>
            <b>troy ounce (ozt):</b> 31.10 grams. A troy ounce contains 2.75
            grams more than a regular ounce. Metals are weighed in troy ounces,
            often referred in short as ozt or oz.
          </li>
        </ul>
      </Layout>
    )
}
export default Contact